import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_ctx.survey)
    ? (_openBlock(), _createBlock(_component_page_block, {
        key: 0,
        breadcrumbs: _ctx.breadcrumbsLink,
        title: _ctx.survey.name
      }, {
        "white-wrapper": _withCtx(() => [
          _createVNode(_component_tm_tabs, {
            modelValue: _ctx.currentRoute,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRoute) = $event)),
            tabs: _ctx.tabs,
            "use-router": "",
            class: "mb-4"
          }, null, 8, ["modelValue", "tabs"]),
          _createVNode(_component_router_view, { survey: _ctx.survey }, null, 8, ["survey"])
        ]),
        _: 1
      }, 8, ["breadcrumbs", "title"]))
    : _createCommentVNode("", true)
}