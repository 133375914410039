
import {
  defineComponent, computed, ref
} from 'vue'
import { useRoute } from 'vue-router'
import PageBlock from '@/components/layout/PageBlock.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  components: {
    PageBlock,
    TmTabs,
  },
  setup() {
    const route = useRoute()

    const survey = computed(() => (getTableData('smsSurvey') as SmsSurveyLine[])
      .find(el => el.id === route.params.id))

    const breadcrumbsLink = computed<BreadcrumbsLink[]>(() => (survey.value)
      ? [
          {
            label: 'SMS surveys',
            name: 'base.services.smsSurveys',
          },
          {
            label: 'Survey results',
          },
        ]
      : [])

    const currentRoute = ref('')
    const tabs = [
      { name: 'Overview', to: { name: 'base.services.smsSurveys.details.overview' } },
      { name: 'Replies', to: { name: 'base.services.smsSurveys.details.replies' } },
      { name: 'Analytics', to: { name: 'base.services.smsSurveys.details.analytics' } },
    ]

    return {
      survey,
      breadcrumbsLink,
      tabs,
      currentRoute,
    }
  },
})
